body {
 margin: 0; padding: 0; 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.map-container {
  /* height: 100vh; */
 position: absolute; top: 0; bottom: 0; width: 100%; 
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  /* font-family: monospace; */
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}
.sidebar label a {
  color: white;
}

.mapboxgl-popup-content p {

  margin:0px;
}
:root {
  --sign-image-width: 50px;
}

.sign-image {
  width: var(--sign-image-width);
}